import api from "@/api";
import router from "@/router";
import ProfileBuilder from "@/services/profile-builder.service";
import { parseJwt } from "../utils/helper";

const state = {
  profileBuilder: new ProfileBuilder(),
  profile: null,
};

const getters = {};

const actions = {
  profileUpdate({ state, dispatch }, profile) {
    return new Promise((resolve) => {
      const body = state.profileBuilder.createProfileUpdateRequestBody(profile);
      api
        .profileUpdate(body)
        .then(() => {
          dispatch("completeProcess");
          resolve();
        })
        .catch((error) => {
          const decoded = state.profileBuilder.decodeProfileUpdateError(
            error.data
          );
          // eslint-disable-next-line no-console
          console.error(decoded.message);
          if (error.status === 401) {
            router.push({ name: "login" });
          }
        });
    });
  },

  getProfile({ state, commit }) {
    return new Promise((resolve) => {
      api
        .getProfile()
        .then((response) => {
          const decoded = state.profileBuilder.decodeProfileResponse(
            response.body
          );
          commit("setProfile", decoded.profile);
          resolve(decoded.profile);
        })
        .catch((error) => {
          if (error.status === 401) {
            router.push({ name: "login" });
          }
        });
    });
  },

  getUserInfo({ state, rootState }) {
    const token = rootState.auth.authToken;
    if (!token) {
      router.push({ name: "login" });
      return false;
    }
    const userId = parseJwt(token.accessToken).sub;
    return new Promise((resolve) => {
      api.getUserInfo(userId).then(
        (response) => {
          const decoded = state.profileBuilder.decodeUserResponse(
            response.body
          );
          resolve(decoded.user);
        },
        (err) => {
          if (err.status === 401) {
            router.push({ name: "login" });
          }
        }
      );
    });
  },
};

const mutations = {
  setProfile(state, profile) {
    state.profile = profile;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
