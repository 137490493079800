<template>
  <div class="Signup__wrapper">
    <transition name="view-animation">
      <router-view class="Signup__view" :key="$route.name"> </router-view>
    </transition>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
