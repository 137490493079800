<template>
  <div class="CountrySelect__wrapper">
    <div class="CountrySelect__selected" @click="toggle">
      <span :class="'CountrySelect__selected-flag flag-' + selectedIso"></span>
      <i
        class="CountrySelect__caret icon-ic_caret"
        :class="{ toggled: isOpen }"
      ></i>
    </div>
    <div
      class="CountrySelect__dropdown"
      v-on-click-outside="hide"
      v-if="isOpen"
    >
      <ul class="CountrySelect__dropdown-list">
        <li
          v-for="c in countries"
          :key="c.iso2.toLowerCase()"
          class="CountrySelect__option"
          @click="onSelect(c)"
          :id="c.iso2"
        >
          <div
            :class="'CountrySelect__option-flag flag-' + c.iso2.toLowerCase()"
          ></div>
          <span class="CountrySelect__option-name">{{ c.name }}</span>
          <span class="CountrySelect__option-code">(+{{ c.code }})</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { COUNTRIES } from "@/constants/countries.constant";

export default {
  data() {
    return {
      isOpen: false,
      countries: COUNTRIES,
      current: "NG",
    };
  },
  props: ["selected"],
  computed: {
    selectedIso() {
      return this.selected ? this.selected.toLowerCase() : null;
    },
  },
  methods: {
    onSelect(country) {
      this.current = country.iso2;
      this.$emit("update:selected", this.current);
      this.hide();
    },
    toggle() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.show();
      }
    },
    show() {
      const countriesArray = Object.values(COUNTRIES);
      const foundCountry = countriesArray.find(
        (country) => country.iso2 === this.selected
      );
      if (foundCountry) {
        this.$nextTick(() => {
          const element = document.getElementById(foundCountry.iso2);
          if (element) {
            element.scrollIntoView({
              // behavior: "smooth",
              block: "start",
            });
          }
        });
      }
    },
    hide() {
      this.isOpen = false;
    },
    onKeyDown(event) {
      if (this.isOpen) {
        if (event.key.length === 1 && /[a-zA-Z]/.test(event.key)) {
          const inputLetter = event.key.toUpperCase();
          const countriesArray = Object.values(COUNTRIES);
          const foundCountry = countriesArray.find((country) =>
            country.name.startsWith(inputLetter)
          );

          if (foundCountry) {
            this.$nextTick(() => {
              const element = document.getElementById(foundCountry.iso2);
              if (element) {
                element.scrollIntoView({
                  // behavior: "smooth",
                  block: "start",
                });
              }
            });
          }
        }
      }
    },
  },
  mounted() {
    this.current = this.selected;
    document.addEventListener("keydown", this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
