/**
 * Return parsed accessToken
 * @param {object}token - token provided by oauth
 * @returns {object}object with these properties:
 * "alg": "RS256", - Signature or encryption algorithm
 * "aud": ["kingschat", ""], - Scopes the token is intended for
 * "cid": "5ce6862b31bb5f00010c9885", - ??
 * "exp": 1558615099998, - Expiration time - seconds since Unix epoch
 * "iss": "kingschat", - Issuer - who created and signed this token
 * "sub": "5bc5cbdcb12712002e5ff6eb" - userId it's belong to - whom the token refers to
 */
export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = decodeURIComponent(
    atob(base64Url)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(base64);
}

export function areArraysEqual(_arr1, _arr2) {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  ) {
    return false;
  }
  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}
