<template>
  <div class="SetNewPassword__wrapper">
    <section name="forgot">
      <div class="SetNewPassword__title">Update password</div>
      <div class="SetNewPassword__description">
        Please type in a new password.
      </div>

      <div class="Grid-row">
        <div class="SetNewPassword__field-with-icon">
          <input
            v-focus
            type="password"
            name="password"
            class="field"
            :class="{ 'is-danger': errors.has('password') }"
            placeholder="New Password"
            v-model="password"
            v-validate="'required'"
            data-vv-delay="500"
            data-vv-as="Password"
            ref="password"
            @keyup.enter="submit"
          />
          <div
            class="SetNewPassword__password-visibility-trigger"
            @click="triggerPasswordVisibility"
          >
            <transition name="fade" mode="out-in">
              <i
                v-if="showPassword"
                key="on"
                class="icon-eye SetNewPassword__password-visibility-trigger--on"
              ></i>
              <i
                v-else
                key="off"
                class="icon-ic_eye-cross SetNewPassword__password-visibility-trigger--off"
              ></i>
            </transition>
          </div>
        </div>
        <span
          v-show="errors.has('password') || error"
          class="form-help is-danger"
        >
          {{ errors.first("password") || error }}
        </span>
      </div>

      <div class="Grid-row">
        <div class="form-actions">
          <a
            @click="submit"
            class="submit-btn"
            :class="{ processing: processing }"
          >
            Update
          </a>
        </div>
      </div>

      <transition name="appear-from-bottom" mode="out-in">
        <div class="SetNewPassword__processing" v-if="processing">
          <spinner />
        </div>
      </transition>
    </section>
  </div>
</template>

<script>
import router from "@/router";
import Spinner from "@/components/common/spinner/spinner";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      password: null,
      processing: false,
      showPassword: false,
      error: null,
    };
  },
  methods: {
    submit() {
      this.$validator.validateAll().then((result) => {
        this.error = null;
        if (result) {
          this.processing = true;
          // waiting for endpoint from @mkaszubowski
          // this.$store.dispatch('', this.password);
        }
      });
    },
    goTo(name) {
      router.push({ name });
    },
    triggerPasswordVisibility() {
      this.showPassword = !this.showPassword;
      this.$refs.password.type = this.showPassword ? "text" : "password";
    },
  },
  beforeMount() {
    if (!this.$route.query.token) {
      router.push({ name: "reset-with-email" });
    }
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
