import api from "@/api";
import router from "@/router";
import DeveloperBuilder from "@/services/developer-builder.service";

const state = {
  developerBuilder: new DeveloperBuilder(),
};

const getters = {};

const actions = {
  getApplicationByClientId({ state }) {
    const clientId = localStorage.getItem("client_id");
    return new Promise((resolve, reject) => {
      api
        .getApplicationByClientId(clientId)
        .then((response) => {
          const decoded = state.developerBuilder.decodeWebApplicationInfo(
            response.body
          );
          resolve(decoded);
        })
        .catch((error) => {
          if (error.status === 401) {
            router.push({ name: "login" });
          }
          reject(error);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
