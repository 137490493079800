<template>
  <div class="UpdatePassword__wrapper">
    <div class="UpdatePassword__title">Update your password</div>

    <section mame="profile" class="Grid-row">
      <div class="UpdatePassword__form-row">
        <input
          type="text"
          name="name"
          class="field"
          :class="{ 'is-danger': errors.has('name') }"
          placeholder="Full Name"
          v-model="profile.name"
          v-validate="'required'"
          data-vv-delay="500"
          data-vv-as="Full Name"
          ref="name"
          @keyup.enter="$refs.username.focus()"
        />
        <span v-show="errors.has('name')" class="form-help is-danger">
          {{ errors.first("name") }}
        </span>
      </div>
      <div class="UpdatePassword__form-row">
        <input
          type="text"
          name="username"
          class="field"
          :class="{ 'is-danger': errors.has('username') }"
          placeholder="Username"
          v-model="profile.username"
          v-validate="'required'"
          data-vv-delay="500"
          data-vv-as="Username"
          ref="username"
          @keyup.enter="$refs.password.focus()"
        />
        <span v-show="errors.has('username')" class="form-help is-danger">
          {{ errors.first("username") }}
        </span>
      </div>
      <div class="UpdatePassword__form-row UpdatePassword__field-with-icon">
        <input
          v-focus
          type="password"
          name="password"
          class="field"
          :class="{ 'is-danger': errors.has('password') }"
          placeholder="Password"
          v-model="profile.password"
          v-validate="'required'"
          data-vv-delay="500"
          data-vv-as="Password"
          ref="password"
          @keyup.enter="submit"
        />
        <div
          class="UpdatePassword__password-visibility-trigger"
          @click="triggerPasswordVisibility"
        >
          <transition name="fade" mode="out-in">
            <i
              v-if="showPassword"
              key="on"
              class="icon-eye UpdatePassword__password-visibility-trigger--on"
            ></i>
            <i
              v-else
              key="off"
              class="icon-ic_eye-cross UpdatePassword__password-visibility-trigger--off"
            ></i>
          </transition>
        </div>
      </div>
      <span v-show="errors.has('password')" class="form-help is-danger">
        {{ errors.first("password") }}
      </span>
      <div class="UpdatePassword__form-row">
        <input
          type="password"
          name="confirmPassword"
          class="field"
          :class="{ 'is-danger': errors.has('confirmPassword') }"
          placeholder="Confirm Password"
          v-model="profile.confirmPassword"
          v-validate="'required|confirmed:password'"
          data-vv-delay="500"
          data-vv-as="Password"
          ref="confirmPassword"
          @keyup.enter="submit"
        />
        <span
          v-show="errors.has('confirmPassword')"
          class="form-help is-danger"
        >
          {{ errors.first("confirmPassword") }}
        </span>
      </div>
    </section>

    <div class="Grid-row">
      <div class="form-actions">
        <a
          @click="submit"
          class="submit-btn"
          :class="{ processing: processing }"
        >
          Update
        </a>
      </div>
      <span v-if="error" class="form-help is-danger">
        {{ error }}
      </span>
    </div>

    <transition name="appear-from-bottom" mode="out-in">
      <div class="UpdatePassword__processing" v-if="processing">
        <spinner />
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from "vue";
import Spinner from "@/components/common/spinner/spinner";
import { mapState } from "vuex";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      profile: {
        name: null,
        username: null,
        password: null,
        confirmPassword: null,
      },
      error: null,
      processing: false,
      showPassword: false,
    };
  },
  computed: mapState(["authToken"]),
  methods: {
    submit() {
      this.$validator.validateAll().then((result) => {
        this.error = null;
        if (result) {
          this.processing = true;
          this.$store.dispatch("profileUpdate", this.profile).then((error) => {
            this.processing = false;
            this.error = error;
          });
        }
      });
    },

    triggerPasswordVisibility() {
      this.showPassword = !this.showPassword;
      this.$refs.password.type = this.showPassword ? "text" : "password";
      this.$refs.confirmPassword.type = this.$refs.password.type;
    },
  },
  beforeMount() {
    if (!this.authToken) {
      this.$store.commit("setAuthToken", {
        accessToken: Vue.prototype.$cookie.get("accessToken"),
      });
    }
    this.$store.dispatch("getProfile").then((profile) => {
      this.profile.name = profile.user.name;
      this.profile.username = profile.user.username;
    });
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
