<template>
  <div class="CommonView__wrapper">
    <img
      class="CommonView__logo"
      @click="goTo('login')"
      src="../../../static/images/logo-horizontal.svg"
      alt="Kc-logo"
    />

    <div class="CommonView__main-container">
      <div class="CommonView__banner">
        <div class="CommonView__banner-title">Free calls, chats & stories!</div>
        <div class="CommonView__banner-images">
          <img
            class="CommonView__banner-image-1"
            src="../../../static/images/banner_part-1.png"
          />
          <img
            class="CommonView__banner-image-2"
            src="../../../static/images/banner_part-2.png"
          />
          <img
            class="CommonView__banner-image-3"
            src="../../../static/images/banner_part-3.png"
          />
        </div>
      </div>
      <div class="CommonView__container">
        <transition name="view-animation">
          <router-view class="CommonView__view" :key="$route.name">
          </router-view>
        </transition>
      </div>
    </div>
    <Recaptcha />
    <div class="CommonView__terms">
      By using this service, you are agreeing to our
      <a
        class="CommonView__terms-link"
        href="https://www.kingsch.at/h/files/kingschat-terms-of-service.pdf"
        target="_blank"
      >
        Terms of Service
      </a>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import Recaptcha from "@/components/recaptcha/recaptcha";

export default {
  name: "CommonView",
  components: {
    Recaptcha,
  },
  methods: {
    goTo(name) {
      router.push({ name });
    },
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
