<template>
  <div class="AppsList__wrapper">
    <div class="AppsList__title">Login</div>
    <a
      class="AppsList__link"
      v-for="(app, index) in list"
      :key="index"
      :href="app.url"
    >
      {{ app.name }}
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
