export const ERROR_MESSAGES = {
  LOGIN_ERROR_0: "Unknown internal error",
  LOGIN_ERROR_1: "Internal error code 1",
  LOGIN_ERROR_2: "Internal error code 2",
  LOGIN_ERROR_3: "Wrong E-mail or Password",
  LOGIN_ERROR_4: "Wrong Phone Number or Password",
  LOGIN_ERROR_5: "Facebook authentication ends with failure",
  LOGIN_ERROR_6: "Wrong Username or Password",
  LOGIN_ERROR_7: "User isn't confirmed",
  LOGIN_ERROR_8: "Problem with Facebook connection",
  LOGIN_ERROR_9: "Phone Number isn't associated with any KingsChat account",
  LOGIN_ERROR_10: "Firebase auth error",
};
