<template>
  <div class="PasswordRecoveryMethod__wrapper">
    <div class="PasswordRecoveryMethod__title">Recover Password</div>
    <div class="Grid-row">
      <div class="form-actions">
        <a @click="goTo('reset-with-email')" class="submit-btn">
          Recover Using Email
        </a>
      </div>
      <div class="form-actions">
        <a @click="goTo('phone-number-login')" class="submit-btn">
          Recover Using Phone Number
        </a>
      </div>
    </div>
    <a class="PasswordRecoveryMethod__go-back" @click="goTo('login')">
      Back to login
    </a>
  </div>
</template>

<script>
import router from "@/router";

export default {
  methods: {
    goTo(name) {
      router.push({ name });
    },
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
