import $root from "../../static/proto-bundle";

class AuthBuilder {
  authBuilder() {
    return $root;
  }

  createLogInRequestBody(data) {
    const builder = this.authBuilder().lookup("LogInRequest");
    const payload = builder.create(data);
    return builder.encode(payload).finish();
  }

  createRecoverPasswordRequestBody(email) {
    const builder = this.authBuilder().lookup("RecoverPasswordRequest");
    const payload = builder.create({
      email,
    });
    return builder.encode(payload).finish();
  }

  createSetNewPasswordBody(token) {
    const builder = this.authBuilder().lookup("SetNewPassword");
    const payload = builder.create({
      token,
    });
    return builder.encode(payload).finish();
  }

  createSignUpRequestBody(data) {
    const builder = this.authBuilder().lookup("SignUpRequest");
    const payload = builder.create(data);
    return builder.encode(payload).finish();
  }

  createTokenRequest(data) {
    const builder = this.authBuilder().lookup("TokenRequest");
    const payload = builder.create(data);
    return builder.encode(payload).finish();
  }

  decodeTokenResponse(payload) {
    const container = this.authBuilder().lookup("TokenResponse");
    return container.decode(new Uint8Array(payload));
  }

  decodeTokenError(payload) {
    const container = this.authBuilder().lookup("TokenError");
    return container.decode(new Uint8Array(payload));
  }

  decodeLogInResponse(payload) {
    const container = this.authBuilder().lookup("LogInResponse");
    return container.decode(new Uint8Array(payload));
  }

  decodeSignUpResponse(payload) {
    const container = this.authBuilder().lookup("SignUpResponse");
    return container.decode(new Uint8Array(payload));
  }

  decodeSignUpError(payload) {
    const container = this.authBuilder().lookup("SignUpError");
    return container.decode(new Uint8Array(payload));
  }

  decodeLogInError(payload) {
    const container = this.authBuilder().lookup("LogInError");
    return container.decode(new Uint8Array(payload));
  }
}

export default AuthBuilder;
