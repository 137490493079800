<template>
  <div class="Spinner__container">
    <div class="Spinner__petal" v-for="petal in 12" :key="petal"></div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
