<template>
  <div class="VerificationEmailSent__wrapper">
    <div class="VerificationEmailSent__title">Verify your account</div>
    <div class="VerificationEmailSent__description">
      We have sent you an e-mail with link. Please check your e-mail inbox and
      follow instructions.
    </div>
    <a class="VerificationEmailSent__go-back" @click="goTo('login')">
      Back to login
    </a>
  </div>
</template>

<script>
import router from "@/router";

export default {
  methods: {
    goTo(name) {
      router.push({ name });
    },
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
