<template>
  <div class="SignupWithEmail__wrapper">
    <div class="SignupWithEmail__title">Create your account</div>

    <section mame="profile" class="Grid-row">
      <div class="SignupWithEmail__form-row">
        <input
          v-focus
          type="text"
          name="email"
          class="field"
          :class="{ 'is-danger': errors.has('email') }"
          placeholder="Email"
          v-model="credentials.email"
          v-validate="'required|email'"
          data-vv-delay="500"
          data-vv-as="Email"
          ref="email"
          @keyup.enter="$refs.name.focus()"
        />
        <span v-show="errors.has('email')" class="form-help is-danger">
          {{ errors.first("email") }}
        </span>
      </div>
      <div class="SignupWithEmail__form-row">
        <input
          type="text"
          name="name"
          class="field"
          :class="{ 'is-danger': errors.has('name') }"
          placeholder="Full Name"
          v-model="credentials.name"
          v-validate="'required'"
          data-vv-delay="500"
          data-vv-as="Full Name"
          ref="name"
          @keyup.enter="$refs.username.focus()"
        />
        <span v-show="errors.has('name')" class="form-help is-danger">
          {{ errors.first("name") }}
        </span>
      </div>
      <div class="SignupWithEmail__form-row">
        <input
          type="text"
          name="username"
          class="field"
          :class="{ 'is-danger': errors.has('username') }"
          placeholder="Username"
          v-model="credentials.username"
          v-validate="'required'"
          data-vv-delay="500"
          data-vv-as="Username"
          ref="username"
          @keyup.enter="$refs.password.focus()"
        />
        <span v-show="errors.has('username')" class="form-help is-danger">
          {{ errors.first("username") }}
        </span>
      </div>
      <div class="SignupWithEmail__form-row SignupWithEmail__field-with-icon">
        <input
          type="password"
          name="password"
          class="field"
          :class="{ 'is-danger': errors.has('password') }"
          placeholder="Password"
          v-model="credentials.password"
          v-validate="'required'"
          data-vv-delay="500"
          data-vv-as="Password"
          ref="password"
          @keyup.enter="submit"
        />
        <div
          class="SignupWithEmail__password-visibility-trigger"
          @click="triggerPasswordVisibility"
        >
          <transition name="fade" mode="out-in">
            <i
              v-if="showPassword"
              key="on"
              class="icon-eye SignupWithEmail__password-visibility-trigger--on"
            ></i>
            <i
              v-else
              key="off"
              class="icon-ic_eye-cross SignupWithEmail__password-visibility-trigger--off"
            ></i>
          </transition>
        </div>
      </div>
      <span v-show="errors.has('password')" class="form-help is-danger">
        {{ errors.first("password") }}
      </span>
    </section>

    <div class="Grid-row">
      <div class="form-actions">
        <button
          @click.stop.prevent="submit"
          class="submit-btn"
          :class="{ processing: processing }"
        >
          Sign Up
        </button>
      </div>
      <span v-if="error" class="form-help is-danger">
        {{ error }}
      </span>
    </div>

    <a class="SignupWithEmail__go-back" @click="goTo('login')">
      Back to login
    </a>

    <transition name="appear-from-bottom" mode="out-in">
      <div class="SignupWithEmail__processing" v-if="processing">
        <spinner />
      </div>
    </transition>
  </div>
</template>

<script>
import router from "@/router";
import Spinner from "@/components/common/spinner/spinner";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      credentials: {
        name: null,
        username: null,
        password: null,
        email: null,
      },
      error: null,
      processing: false,
      showPassword: false,
    };
  },
  methods: {
    submit() {
      this.$validator.validateAll().then((result) => {
        this.error = null;
        if (result) {
          this.$bus.$emit("verify-sign-up-recaptcha", this.onReCAPTCHAVerified);
        }
      });
    },
    goTo(name) {
      router.push({ name });
    },
    triggerPasswordVisibility() {
      this.showPassword = !this.showPassword;
      this.$refs.password.type = this.showPassword ? "text" : "password";
    },
    onReCAPTCHAVerified(token) {
      this.processing = true;
      this.$store
        .dispatch("signupWithEmail", { token, ...this.credentials })
        .then((error) => {
          this.error = error;
          this.processing = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
