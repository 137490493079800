import $root from "../../static/proto-bundle";

class ProfileBuilder {
  authBuilder() {
    return $root;
  }

  createProfileUpdateRequestBody(data) {
    const builder = this.authBuilder().lookup("ProfileUpdateRequest");
    const payload = builder.create({
      ...data,
    });
    return builder.encode(payload).finish();
  }

  decodeProfileResponse(payload) {
    const container = this.authBuilder().lookup("ProfileResponse");
    return container.decode(new Uint8Array(payload));
  }

  decodeProfileUpdateResponse(payload) {
    const container = this.authBuilder().lookup("ProfileUpdateResponse");
    return container.decode(new Uint8Array(payload));
  }

  decodeProfileUpdateError(payload) {
    const container = this.authBuilder().lookup("ProfileUpdateError");
    return container.decode(new Uint8Array(payload));
  }

  decodeUserResponse(payload) {
    const container = this.authBuilder().lookup("UserResponse");
    return container.decode(new Uint8Array(payload));
  }
}

export default ProfileBuilder;
