import Vue from "vue";
import VueCookie from "vue-cookie";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueResource from "vue-resource";
import VeeValidate from "vee-validate";
import { directive as onClickOutside } from "vue-on-click-outside";
import focus from "./directives/focus.directive";
import fallbackSrc from "./directives/fallback-src.directive";
import App from "./App";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();
Vue.use(VueCookie);
Vue.use(VeeValidate);
Vue.use(VueResource);
Vue.directive("on-click-outside", onClickOutside);
Vue.directive("focus", focus);
Vue.directive("fallback-src", fallbackSrc);

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    Vue,
    dsn: "https://445e5eac43884b5ca9d74ea50d4948c9@sentry.kingsch.at/14",
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: [
      "Network Error",
      "We have blocked all requests from this device due to unusual activity. Try again later",
      "The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired.",
    ],
    tracesSampleRate: 0.8,
  });
}

/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  router,
  template: "<App/>",
  components: { App },
});
