import $root from "../../static/proto-bundle";

class DeveloperBuilder {
  decodeWebApplicationInfo(payload) {
    const container = $root.lookup("WebApplicationInfo.Response");
    return container.decode(new Uint8Array(payload));
  }
}

export default DeveloperBuilder;
