<template>
  <div>
    <vue-recaptcha
      v-if="anyReCAPTCHA"
      @expired="onCaptchaExpired"
      @verify="onCaptchaVerified"
      ref="recaptcha"
      :sitekey="sitekey"
      size="invisible"
    >
    </vue-recaptcha>
  </div>
</template>

<script>
/* reCAPTCHA
 * use VueRecaptcha from 'vue-recaptcha'
 * <vue-recaptcha/> :
 * params:
 * sitekey (required) - ReCAPTCHA site key
 * theme (optional) - The color theme for reCAPTCHA
 * type (optional) - The type of reCAPTCHA
 * size (optional) - The size of reCAPTCHA
 * tabindex (optional) - The tabindex of reCAPTCHA
 * badge (optional) (Invisible ReCAPTCHA only) - Position of the reCAPTCHA badge
 * emits:
 *   verify(response) Emit on reCAPTCHA verified response is the successful reCAPTCHA response
 *   expired() Emit on reCAPTCHA expired
 *   render(id) Emit on reCAPTCHA mounted on DOM id is the widget id of the component
 * methods:
 *   reset - Reset reCAPTCHA instance
 *   execute - Invoke reCAPTCHA challenge
 */

import VueRecaptcha from "vue-recaptcha";
import { REMOTE_PATHS } from "@/constants/remote-paths.constant";

export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      callbackFn: null,
    };
  },
  computed: {
    sitekey() {
      return REMOTE_PATHS.RECAPTCHA_SITE_KEY;
    },
    anyReCAPTCHA() {
      return this.enabledLoginReCAPTCHA || this.enabledSignUpReCAPTCHA;
    },
    enabledLoginReCAPTCHA() {
      return REMOTE_PATHS.ENABLE_LOGIN_RECAPTCHA_VERIFICATION === "1";
    },
    enabledSignUpReCAPTCHA() {
      return REMOTE_PATHS.ENABLE_SIGNUP_RECAPTCHA_VERIFICATION === "1";
    },
  },
  created() {
    const verifyLogin = this.enabledLoginReCAPTCHA
      ? this.verify
      : this.callCallbackFn;
    const verifySignUp = this.enabledSignUpReCAPTCHA
      ? this.verify
      : this.callCallbackFn;
    this.$bus.$on("verify-login-recaptcha", verifyLogin);
    this.$bus.$on("verify-sign-up-recaptcha", verifySignUp);
  },
  mounted() {
    if (this.anyReCAPTCHA) {
      this.setUpReCAPTCHA();
    }
  },
  methods: {
    setUpReCAPTCHA() {
      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
      );
      recaptchaScript.async = true;
      recaptchaScript.defer = true;
      document.head.appendChild(recaptchaScript);
    },
    verify(callback) {
      this.callbackFn = callback;
      this.$refs.recaptcha.execute();
    },
    callCallbackFn(callback) {
      callback();
    },
    onCaptchaVerified(token) {
      if (this.callbackFn) {
        this.callbackFn(token);
      }
      this.callbackFn = null;
      this.$refs.recaptcha.reset();
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>
