<template>
  <div class="SignupMethod__wrapper">
    <div class="SignupMethod__title">Choose Signup Method</div>
    <div class="SignupMethod__description">
      You can register using email address or phone number.
    </div>
    <div class="Grid-row">
      <div class="form-actions">
        <a @click="goTo('signup-with-email')" class="submit-btn">
          Signup Using Email
        </a>
      </div>
      <div class="form-actions">
        <a @click="goTo('signup-with-phone')" class="submit-btn">
          Signup Using Phone Number
        </a>
      </div>
    </div>
    <a class="SignupMethod__go-back" @click="goTo('login')"> Back to login </a>
  </div>
</template>

<script>
import router from "@/router";

export default {
  methods: {
    goTo(name) {
      router.push({ name });
    },
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
