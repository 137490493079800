<template>
  <div class="PasswordRecovery__wrapper">
    <transition name="view-animation">
      <router-view class="PasswordRecovery__view" :key="$route.name">
      </router-view>
    </transition>
    <a class="PhoneNumberLogin__go-back" @click="goTo('login')">
      Back to login
    </a>
  </div>
</template>

<script>
import router from "@/router";

export default {
  methods: {
    goTo(name) {
      router.push({ name });
    },
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
