import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/login/login";
import PasswordRecovery from "@/components/password-recovery/password-recovery";
import PhoneNumberLogin from "@/components/password-recovery/phone-number-login/phone-number-login";
import CodeVerifying from "@/components/password-recovery/code-verifying/code-verifying";
import ResetWithEmail from "@/components/password-recovery/reset-with-email/reset-with-email";
import VerificationEmailSent from "@/components/verification-email-sent/verification-email-sent";
import SetNewPassword from "@/components/password-recovery/set-new-password/set-new-password";
import Signup from "@/components/signup/signup";
import ChooseSignupMethod from "@/components/signup/signup-method/signup-method";
import PasswordRecoveryMethod from "@/components/password-recovery/password-recovery-method/password-recovery-method";
import PhoneVerifying from "@/components/signup/phone-verifying/phone-verifying";
import SignupCodeVerifying from "@/components/signup/signup-code-verifying/signup-code-verifying";
import CompleteProfile from "@/components/signup/complete-profile/complete-profile";
import SignupWithEmail from "@/components/signup/signup-with-email/signup-with-email";
import UpdatePassowrd from "@/components/update-password/update-password";
import Processing from "@/components/processing/processing";
import Permission from "@/components/permission/permission";
import Apps from "@/components/apps-list/apps-list";

Vue.use(Router);

const router = new Router({
  root: "/",
  mode: "history",
  routes: [
    {
      path: "/",
      name: "login",
      meta: { auth: false, title: "Login" },
      component: Login,
    },
    {
      path: "/apps",
      name: "apps",
      meta: { auth: true },
      component: Apps,
    },
    {
      path: "/password-recovery",
      component: PasswordRecovery,
      children: [
        {
          path: "",
          name: "password-recovery-method",
          meta: { auth: false, title: "Choose Password Recovery Method" },
          component: PasswordRecoveryMethod,
        },
        {
          path: "phone-number-login",
          name: "phone-number-login",
          meta: { auth: false, title: "Password Recovery with Phone Number" },
          component: PhoneNumberLogin,
        },
        {
          path: "code-verifying",
          name: "code-verifying",
          meta: { auth: false, title: "Phone Verifying " },
          component: CodeVerifying,
        },
        {
          path: "reset-with-email",
          name: "reset-with-email",
          meta: { auth: false, title: "Password Recovery with Email" },
          component: ResetWithEmail,
        },
        {
          path: "set-new-password",
          name: "set-new-password",
          meta: { auth: false, title: "Set New Password" },
          component: SetNewPassword,
        },
      ],
    },
    {
      path: "/signup",
      component: Signup,
      children: [
        {
          path: "",
          name: "signup-method",
          meta: { auth: false, title: "Choose Registration Method" },
          component: ChooseSignupMethod,
        },
        {
          path: "signup-with-phone",
          name: "signup-with-phone",
          meta: { auth: false, title: "Registration" },
          component: PhoneVerifying,
        },
        {
          path: "sign-up-code-verifying",
          name: "signup-code-verifying",
          meta: { auth: false, title: "Phone Verifying" },
          component: SignupCodeVerifying,
        },
        {
          path: "complete-profile",
          name: "complete-profile",
          meta: { auth: false, title: "Complete Your Profile" },
          component: CompleteProfile,
        },
        {
          path: "signup-with-email",
          name: "signup-with-email",
          meta: { auth: false, title: "Register with Email" },
          component: SignupWithEmail,
        },
      ],
    },
    {
      path: "/verification-email-sent",
      name: "verification-email-sent",
      meta: { auth: false, title: "Email Verifying" },
      component: VerificationEmailSent,
    },
    {
      path: "/update-password",
      name: "update-password",
      meta: { auth: true, title: "Update Your Password" },
      component: UpdatePassowrd,
    },
    {
      path: "/processing",
      name: "processing",
      meta: { auth: false, title: "Processing" },
      component: Processing,
    },
    {
      path: "/permission",
      name: "permission",
      meta: { auth: false, title: "Grant permission" },
      component: Permission,
    },
    { path: "*", redirect: "/" },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = `Accounts | ${to.meta.title}` || "Accounts";
  next();
});

export default router;
