import Vue from "vue";
import { REMOTE_PATHS } from "./constants/remote-paths.constant";

const getHeaders = (noAuth) => {
  const headers = {
    Accept: "application/x-protobuf",
  };
  if (!noAuth) {
    headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
  }
  return headers;
};

const postHeaders = () => {
  const token = localStorage.getItem("accessToken");
  const headers = {
    Accept: "application/x-protobuf",
    "Content-Type": "application/x-protobuf",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

const constructPath = (path) => `${REMOTE_PATHS.API_PATH}${path}`;

const api = {
  _get({ path, params, noAuth }) {
    return Vue.http.get(constructPath(path), {
      headers: getHeaders(noAuth),
      params,
      responseType: "arraybuffer",
    });
  },
  _post({ path, body }) {
    return Vue.http.post(constructPath(path), body, {
      headers: postHeaders(),
      responseType: "arraybuffer",
    });
  },
  _put({ path, body }) {
    return Vue.http.put(constructPath(path), body, {
      headers: postHeaders(),
      responseType: "arraybuffer",
    });
  },
  _patch({ path, body }) {
    return Vue.http.patch(constructPath(path), body, {
      headers: postHeaders(),
      responseType: "arraybuffer",
    });
  },
  login(body) {
    return this._post({
      path: "/api/authentication/log_in",
      body,
    });
  },
  recoverPassword(body) {
    return this._post({
      path: "/api/authentication/recover_password",
      body,
    });
  },
  setNewPassword(body) {
    return this._post({
      path: "/api/authentication/verify_email",
      body,
    });
  },
  signUp(body) {
    return this._post({
      path: "/api/authentication/sign_up",
      body,
    });
  },
  profileUpdate(body) {
    return this._patch({
      path: "/api/profile",
      body,
    });
  },
  getUserInfo(userId) {
    return this._get({ path: `/api/users/${userId}`, noAuth: true });
  },
  getProfile() {
    return this._get({
      path: "/api/profile",
    });
  },
  refreshToken(body) {
    return this._post({
      path: "/oauth2/token",
      body,
    });
  },
  getApplicationByClientId(clientId) {
    return this._get({ path: `/developer/api/application/${clientId}` });
  },
};

export default api;
