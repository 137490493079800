<template>
  <div class="ResetWithEmail__wrapper">
    <div class="ResetWithEmail__title">Forgot password</div>
    <div class="ResetWithEmail__description">
      Please enter your e-mail address. We will send you a code to reset the
      password.
    </div>

    <div class="Grid-row">
      <input
        v-focus
        type="email"
        name="email"
        class="field"
        :class="{ 'is-danger': errors.has('email') }"
        placeholder="Your E-mail"
        v-model="email"
        v-validate="'required|email'"
        data-vv-delay="500"
        data-vv-as="E-mail"
        ref="email"
        @keyup.enter="submit"
      />
      <span v-show="errors.has('email') || error" class="form-help is-danger">
        {{ errors.first("email") || error }}
      </span>
    </div>

    <div class="Grid-row">
      <div class="form-actions">
        <a
          @click="submit"
          class="submit-btn"
          :class="{ processing: processing }"
        >
          Send The Code
        </a>
      </div>
    </div>
    <a class="ResetWithEmail__go-back" @click="goTo('login')">
      Back to login
    </a>
    <transition name="appear-from-bottom" mode="out-in">
      <div class="ResetWithEmail__processing" v-if="processing">
        <spinner />
      </div>
    </transition>
  </div>
</template>

<script>
import router from "@/router";
import Spinner from "@/components/common/spinner/spinner";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      email: null,
      processing: false,
      error: null,
    };
  },
  methods: {
    submit() {
      this.$validator.validateAll().then((result) => {
        this.error = null;
        if (result) {
          this.processing = true;
          this.$store.dispatch("recoverPassword", this.email);
        }
      });
    },
    goTo(name) {
      router.push({ name });
    },
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
