import Vue from "vue";
import api from "@/api";
import router from "@/router";
import libphonenumber from "google-libphonenumber";
import firebase from "firebase/app";
import "firebase/auth";
import { VALIDATION_REGEX } from "@/constants/validation-regex.constant";
import { REMOTE_PATHS } from "@/constants/remote-paths.constant";
import { ERROR_MESSAGES } from "@/constants/error-messages.constant";
import AuthBuilder from "@/services/auth-builder.service";
import { parseJwt, areArraysEqual } from "../utils/helper";

const state = {
  phoneUtil: libphonenumber.PhoneNumberUtil.getInstance(),
  PNF: libphonenumber.PhoneNumberFormat,
  currentCredentialType: null,
  currentFormattedCredential: null,
  authBuilder: new AuthBuilder(),
  confirmationResult: null,
  recaptchaVerifier: null,
  firebaseToken: null,
  authToken: null,
  allowedScopes: null,
};

const getters = {
  currentFormattedCredential(state) {
    return state.currentFormattedCredential;
  },
};

const actions = {
  checkPhoneNumber({ state }, { phone_number, country_code }) {
    return new Promise((resolve) => {
      const noExcepetion = (func) => {
        let ret;
        try {
          ret = func();
        } catch (err) {
          ret = false;
        }
        return ret;
      };
      const pn = noExcepetion(() =>
        state.phoneUtil.parse(phone_number, country_code)
      );
      const isValid = pn ? state.phoneUtil.isValidNumber(pn) : pn;
      if (isValid) {
        resolve(state.phoneUtil.format(pn, state.PNF.E164).replace("+", ""));
      } else {
        resolve(false);
      }
    });
  },

  checkCredential({ commit }, credential) {
    const isValidEmail = (credential) => {
      const re = VALIDATION_REGEX.EMAIL;
      const isValid = re.test(credential);
      if (isValid) {
        commit("setCurrentFormattedCredential", credential);
      }
      return isValid;
    };

    const isValidPhoneNumber = (credential) => {
      const phoneRegex = /^\d{4,14}$/;
      return phoneRegex.test(credential);
    };

    const isValidUsername = (credential) => {
      const re = VALIDATION_REGEX.USERNAME;
      const isValid = credential ? re.test(credential) : false;
      if (isValid) {
        commit("setCurrentFormattedCredential", credential);
      }
      return isValid;
    };
    switch (true) {
      case isValidEmail(credential):
        commit("setCurrentCredentialType", "email");
        break;
      case isValidPhoneNumber(credential):
        commit("setCurrentCredentialType", "phone_number");
        break;
      case isValidUsername(credential):
        commit("setCurrentCredentialType", "username");
        break;
      default:
        commit("setCurrentCredentialType", false);
        break;
    }
  },

  login({ state, dispatch }, { token, credentials }) {
    return new Promise((resolve, reject) => {
      let data;
      switch (state.currentCredentialType) {
        case "email":
          data = {
            email: {
              email: credentials.login,
              password: credentials.password,
            },
          };
          break;
        case "phone_number": {
          data = {
            phone: {
              phoneNumber: `+${credentials.login}`,
              password: credentials.password,
            },
          };
          break;
        }
        case "username":
          data = {
            username: {
              username: credentials.login,
              password: credentials.password,
            },
          };
          break;
        case "firebase_auth":
          data = {
            firebaseAuth: credentials,
          };
          break;
        default:
          reject("Login is not correct");
          break;
      }
      data.clientId = localStorage.getItem("client_id");
      data.scopes = JSON.parse(localStorage.getItem("scopes"));
      data.recaptcha = {
        version: 0,
        token,
        platform: 2,
      };
      data.redirectUri = router.history.current.query.redirect_uri
        ? router.history.current.query.redirect_uri
        : "";
      const body = state.authBuilder.createLogInRequestBody(data);
      api.login(body).then(
        (response) => {
          const decoded = state.authBuilder.decodeLogInResponse(response.body);
          dispatch("completeProcess", decoded.token);
          resolve(decoded);
        },
        (err) => {
          const error_code = state.authBuilder.decodeLogInError(err.data).code;
          const error_message =
            ERROR_MESSAGES[`LOGIN_ERROR_${error_code}`] ||
            state.authBuilder.decodeLogInError(err.data).message;
          reject(error_message);
        }
      );
    });
  },

  checkAndLogin({ dispatch }, { token, ...credentials }) {
    dispatch("checkCredential", credentials.login);
    return dispatch("login", { token, credentials });
  },

  logout({ commit }) {
    const domain = REMOTE_PATHS.COOKIE_DOMAIN;

    commit("setAuthToken", null);
    Vue.prototype.$cookie.delete("accessToken", { domain });
    Vue.prototype.$cookie.delete("refreshToken", { domain });
    router.push({ name: "login" });
  },

  verifyPhone({ commit, dispatch }, { phone_number, callback }) {
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: REMOTE_PATHS.FIREBASE_API_KEY,
        authDomain: REMOTE_PATHS.FIREBASE_AUTH_DOMAIN,
        databaseURL: REMOTE_PATHS.FIREBASE_DATABASE_URL,
        projectId: REMOTE_PATHS.FIREBASE_PROJECT_ID,
        storageBucket: REMOTE_PATHS.FIREBASE_STORAGE_BUCKET,
        messagingSenderId: REMOTE_PATHS.FIREBASE_MESSAGING_SENDER_ID,
      });
    }
    commit("setCurrentFormattedCredential", phone_number);
    commit("setCurrentCredentialType", "firebase_auth");
    dispatch("sendSmsCode", callback);
  },

  sendSmsCode({ state, commit }, callback) {
    if (state.currentCredentialType === "firebase_auth") {
      if (state.recaptchaVerifier) {
        state.recaptchaVerifier.clear();
      }
      state.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        { size: "visible" }
      );

      firebase
        .auth()
        .signInWithPhoneNumber(
          `+${state.currentFormattedCredential}`,
          state.recaptchaVerifier
        )
        .then((confirmationResult) => {
          commit("setConfirmationResult", confirmationResult);
          state.recaptchaVerifier.clear();
          commit("setRecaptchaVerifier", null);
          callback("");
        })
        .catch((error) => {
          callback(error.message);
          state.recaptchaVerifier.clear();
          commit("setConfirmationResult", null);
          commit("setRecaptchaVerifier", null);
        });
    } else {
      callback(false);
    }
  },

  codeVerifying({ state, commit }, { code, callback, error }) {
    state.confirmationResult.confirm(code).then(
      () => {
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((idToken) => {
            commit("setFirebaseToken", idToken);
            callback({
              phoneNumber: state.currentFormattedCredential,
              firebaseToken: idToken,
            });
          });
      },
      (err) => {
        error(err);
      }
    );
  },

  recoverPassword({ state }, email) {
    const body = state.authBuilder.createRecoverPasswordRequestBody(email);
    api.recoverPassword(body).then(() => {
      router.push({ name: "verification-email-sent" });
    });
  },

  verifyEmail({ state }, token) {
    const body = state.authBuilder.createSetNewPasswordBody(token);
    api.setNewPassword(body).then(() => {});
  },

  signup({ state, dispatch }, { token, name, username, password }) {
    return new Promise((resolve) => {
      const pn = state.phoneUtil.parse(`+${state.currentFormattedCredential}`);
      const countryCode = `+${pn.getCountryCode()}`;
      const phone = `${pn.getNationalNumber()}`;
      const body = state.authBuilder.createSignUpRequestBody({
        phone: {
          countryCode,
          phone,
          name,
          username,
          password,
          firebaseToken: state.firebaseToken,
        },
        clientId: localStorage.getItem("client_id"),
        scopes: JSON.parse(localStorage.getItem("scopes")),
        recaptcha: {
          version: 0,
          token,
          platform: 2,
        },
      });
      api.signUp(body).then(
        (response) => {
          const decoded = state.authBuilder.decodeSignUpResponse(response.data);
          dispatch("completeProcess", decoded.token);
        },
        (error) => {
          const decoded = state.authBuilder.decodeSignUpError(error.data);
          resolve(decoded.message);
        }
      );
    });
  },

  signupWithEmail(
    { state, dispatch },
    { token, email, name, username, password }
  ) {
    return new Promise((resolve) => {
      const body = state.authBuilder.createSignUpRequestBody({
        email: {
          email,
          name,
          username,
          password,
        },
        clientId: localStorage.getItem("client_id"),
        scopes: JSON.parse(localStorage.getItem("scopes")),
        recaptcha: {
          version: 0,
          token,
          platform: 2,
        },
      });
      api.signUp(body).then(
        (response) => {
          const decoded = state.authBuilder.decodeSignUpResponse(response.data);
          if (decoded.unconfirmed) {
            router.push({ name: "verification-email-sent" });
          } else {
            dispatch("completeProcess", decoded.token);
          }
        },
        (error) => {
          const decoded = state.authBuilder.decodeSignUpError(error.data);
          resolve(decoded.message);
        }
      );
    });
  },

  completeProcess({ state, commit, dispatch }, token) {
    commit("setAuthToken", token);
    const parsedToken = parseJwt(token.accessToken);
    const scopes = parsedToken.aud;
    const rootScopes = ["kingschat", "conference_calls", "kingscloud"];
    const clientId = localStorage.getItem("client_id");

    const scopesIncludeRootScopes =
      scopes &&
      rootScopes.reduce((acc, curr) => acc || scopes.includes(curr), false);
    const clientIdIsKingschat = clientId === "com.kingschat";
    const allScopesWereAllowed =
      scopes && areArraysEqual(state.allowedScopes, scopes);

    let complete = false;
    if (clientIdIsKingschat || scopesIncludeRootScopes) {
      localStorage.removeItem("post_message");
      complete = true;
    } else if (allScopesWereAllowed) {
      complete = true;
    }

    if (complete) {
      dispatch("completeRedirect", token);
    } else {
      router.push({ name: "permission" });
    }
  },

  completeRedirect({ dispatch }, token) {
    const url = localStorage.getItem("redirect_uri");
    const doPostRedirect = JSON.parse(localStorage.getItem("post_redirect"));
    const doPostMessage = JSON.parse(localStorage.getItem("post_message"));

    const redirect = () => {
      Vue.prototype.$cookie.set("accessToken", token.accessToken, {
        expires: `${token.expiresInMillis / 1000}s`,
        domain: REMOTE_PATHS.COOKIE_DOMAIN,
      });
      Vue.prototype.$cookie.set("refreshToken", token.refreshToken, {
        domain: REMOTE_PATHS.COOKIE_DOMAIN,
        expires: 1000,
      });

      if (url) {
        window.location.href = url;
      } else {
        router.push({ name: "apps" });
      }
    };

    const postRedirect = () => {
      const form = document.createElement("form");
      form.action = url;
      form.method = "POST";

      const accessTokenInput = document.createElement("input");
      accessTokenInput.type = "hidden";
      accessTokenInput.name = "accessToken";
      accessTokenInput.value = token.accessToken;

      const refreshToken = document.createElement("input");
      refreshToken.type = "hidden";
      refreshToken.name = "refreshToken";
      refreshToken.value = token.refreshToken;

      form.appendChild(accessTokenInput);
      form.appendChild(refreshToken);

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    };

    const postMessage = () => {
      window.opener.postMessage(
        {
          accessToken: token.accessToken,
          // Without 3 seconds just to make sure it will be valid
          expiresInMillis: token.expiresInMillis - 3000,
          refreshToken: token.refreshToken,
        },
        decodeURIComponent(url)
      );
    };

    dispatch("finalize");

    if (doPostRedirect) {
      postRedirect();
    } else if (doPostMessage) {
      postMessage();
    } else {
      redirect();
    }
  },

  refreshTokenAndComplete({ state, dispatch }) {
    const refreshToken = state.authToken ? state.authToken.refreshToken : null;
    if (refreshToken) {
      router.push({ name: "processing" });
    } else {
      router.push({ name: "login" });
      return;
    }

    const body = state.authBuilder.createTokenRequest({
      clientId: localStorage.getItem("client_id"),
      grantType: 2,
      refreshToken,
    });
    api.refreshToken(body).then(
      (response) => {
        const decoded = state.authBuilder.decodeTokenResponse(response.data);
        dispatch("completeProcess", decoded);
      },
      (error) => {
        const decoded = state.authBuilder.decodeTokenError(error.data);
        // eslint-disable-next-line no-console
        console.info("decoded Error", decoded);
        router.push({ name: "login" });
      }
    );
  },

  initialize() {
    if (router.history.current.query.redirect_uri) {
      let redirect_uri = router.history.current.query.redirect_uri;
      if (
        router.history.current.query.redirect_uri.includes("web.kingsch.at")
      ) {
        redirect_uri = "https://kingschat.online/";
      }
      localStorage.setItem("redirect_uri", redirect_uri);
      localStorage.setItem("client_id", router.history.current.query.client_id);
      localStorage.setItem("scopes", router.history.current.query.scopes);
      localStorage.setItem(
        "post_redirect",
        router.history.current.query.post_redirect || false
      );
      localStorage.setItem(
        "post_message",
        router.history.current.query.post_message || false
      );
      localStorage.removeItem("accessToken");
    } else if (
      window.location.origin === "https://accounts.staging.kingsch.at"
    ) {
      localStorage.setItem("redirect_uri", "https://stg.kingschat.online");
      localStorage.setItem("client_id", "com.kingschat.debug");
      localStorage.setItem("scopes", JSON.stringify(["kingschat"]));
      localStorage.setItem("post_redirect", true);
      localStorage.setItem("post_message", false);
      localStorage.removeItem("accessToken");
    } else if (window.location.origin === "http://localhost:5050") {
      localStorage.setItem("redirect_uri", "http://localhost:3000");
      localStorage.setItem("client_id", "com.kingschat.debug");
      localStorage.setItem("scopes", JSON.stringify(["kingschat"]));
      localStorage.setItem("post_redirect", false);
      localStorage.setItem("post_message", false);
      localStorage.removeItem("accessToken");
    } else if (window.location.origin === "https://accounts.kingsch.at") {
      localStorage.setItem("redirect_uri", "https://kingschat.online");
      localStorage.setItem("client_id", "com.kingschat");
      localStorage.setItem("scopes", JSON.stringify(["kingschat"]));
      localStorage.setItem("post_redirect", true);
      localStorage.setItem("post_message", false);
      localStorage.removeItem("accessToken");
    }
  },

  finalize() {
    localStorage.removeItem("redirect_uri");
    localStorage.removeItem("client_id");
    localStorage.removeItem("scopes");
    localStorage.removeItem("post_redirect");
    localStorage.removeItem("post_message");
    localStorage.removeItem("accessToken");
  },
};

const mutations = {
  setCurrentCredentialType(state, type) {
    state.currentCredentialType = type;
  },

  setCurrentFormattedCredential(state, value) {
    state.currentFormattedCredential = value;
  },

  setConfirmationResult(state, value) {
    state.confirmationResult = value;
  },
  setRecaptchaVerifier(state) {
    state.recaptchaVerifier = null;
  },

  setFirebaseToken(state, value) {
    state.firebaseToken = value;
  },

  setAuthToken(state, value) {
    if (value) {
      localStorage.setItem("accessToken", value.accessToken);
    } else {
      localStorage.removeItem("accessToken");
    }
    state.authToken = value;
  },

  setAllowedScopes(state, value) {
    state.allowedScopes = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
