const fallbackSrc = {
  bind: (el, binding) => {
    const handler = () => {
      el.src = binding.value;
      el.removeEventListener("error", handler);
    };
    const unsubscribe = () => {
      el.removeEventListener("error", handler);
      el.removeEventListener("load", unsubscribe);
    };

    if (!el.src) el.src = binding.value;
    else {
      el.addEventListener("error", handler);
      el.addEventListener("load", unsubscribe);
    }
  },
  update: (el, binding) => {
    if (!el.src) el.src = binding.value;
  },
};

export default fallbackSrc;
