<template>
  <div class="SignupCodeVerifying__wrapper">
    <div class="SignupCodeVerifying__title">Verify your account</div>
    <div class="SignupCodeVerifying__description">
      We have sent you a SMS with an activation code to the number +{{
        phoneNumber
      }}. Please check your phone and enter the code below.
    </div>

    <div class="Grid-row">
      <input
        v-focus
        type="text"
        name="code"
        class="field"
        :class="{ 'is-danger': errors.has('code') }"
        placeholder="Your Code"
        v-model="code"
        v-validate="'required|numeric'"
        data-vv-delay="500"
        data-vv-as="Code"
        ref="code"
        @keyup.enter="submit"
      />
      <transition name="appear-from-bottom" mode="out-in">
        <span v-show="errors.has('code') || error" class="form-help is-danger">
          {{ errors.first("code") || error }}
        </span>
      </transition>
    </div>

    <div class="Grid-row">
      <div class="form-actions">
        <a
          @click="submit"
          class="submit-btn"
          :class="{ processing: processing }"
        >
          Verify My Number
        </a>
      </div>
    </div>

    <a class="SignupCodeVerifying__go-back" @click="goTo('login')">
      Back to login
    </a>

    <transition name="appear-from-bottom" mode="out-in">
      <div class="SignupCodeVerifying__processing" v-if="processing" key="true">
        <spinner />
        <span class="SignupCodeVerifying__processing-text">
          Verifying number
        </span>
      </div>
      <div v-else key="false" class="SignupCodeVerifying__alternative">
        <div class="SignupCodeVerifying__description">Didn't get the code?</div>
        <div class="SignupCodeVerifying__alternativeactions">
          <a class="SignupCodeVerifying__link" @click="resend"> Resend Code </a>
          or
          <a
            class="SignupCodeVerifying__link"
            @click="goTo('signup-with-email')"
          >
            Sign up with E-mail Instead
          </a>
        </div>
      </div>
    </transition>
    <span id="recaptcha-container"></span>
  </div>
</template>

<script>
import router from "@/router";
import Spinner from "@/components/common/spinner/spinner";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      code: null,
      processing: false,
      error: null,
    };
  },
  computed: {
    phoneNumber() {
      return this.$store.getters.currentFormattedCredential;
    },
  },
  methods: {
    submit() {
      this.$validator.validateAll().then((result) => {
        this.error = null;
        if (result) {
          this.processing = true;
          this.$store.dispatch("codeVerifying", {
            code: this.code,
            callback: () => this.goTo("complete-profile"),
            error: (data) => {
              this.processing = false;
              this.error = data.message;
            },
          });
        }
      });
    },
    goTo(name) {
      router.push({ name });
    },
    resend() {
      this.processing = true;
      this.error = null;
      this.code = null;
      this.$store.dispatch("sendSmsCode", () => {
        this.processing = false;
      });
    },
  },
  beforeMount() {
    if (!this.$store.getters.currentFormattedCredential) {
      router.push({ name: "signup" });
    }
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
