<template>
  <div class="Processing__wrapper">
    <spinner></spinner>
  </div>
</template>

<script>
import Spinner from "@/components/common/spinner/spinner";

export default {
  components: {
    Spinner,
  },
};
</script>

<style scoped lang="scss">
.Processing__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
