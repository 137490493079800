<template>
  <div class="Permission" v-if="user && application">
    <section v-if="user" class="Permission__profile">
      <img
        v-fallback-src="'/static/images/default-user-avatar.jpg'"
        :src="avatarUrl"
        :alt="avatarAltText"
        class="Permission__profile-avatar avatar-image"
      />
      <h1 class="Permission__profile-name">Hi {{ user.name }}</h1>
      <div class="Permission__profile-options">
        <span>If this is not you, </span>
        <a class="Permission__log-out" @click="logout">log out</a>
      </div>
    </section>
    <section v-if="application" class="Permission__scopes">
      <div class="Permission__app-info">
        <strong>{{ application.applicationName }}</strong> would like to
      </div>
      <ul class="Permission__scopes-list">
        <li class="Permission__scope-element" v-if="messagesPermission">
          <i class="icon-commenting-o Permission__scope-icon" />
          <div>
            Be able to send KingsChat messages in your name to any KingsChat's
            user
          </div>
        </li>
      </ul>
    </section>
    <p class="Permission__terms">
      By clicking Allow, you allow this app and KingsChat to use your
      information in accordance with their respective terms of service and
      privacy policies.
    </p>
    <section class="Permission__actions">
      <button
        class="Permission__action Permission__action-deny"
        @click="onDeny"
      >
        Deny
      </button>
      <button class="submit-btn Permission__action" @click="onAllowed">
        Allow
      </button>
    </section>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Permission",
  data() {
    return {
      user: null,
      scopes: JSON.parse(localStorage.getItem("scopes")),
      application: null,
    };
  },
  computed: {
    avatarUrl() {
      return (
        (this.user && this.user.avatarUrl) ||
        "/static/images/default-user-avatar.jpg"
      );
    },
    avatarAltText() {
      return `${this.name ? this.name : "User"} avatar picture`;
    },
    messagesPermission() {
      return this.scopes && this.scopes.includes("send_chat_message");
    },
  },
  beforeMount() {
    this.getUserInfo();
    this.getApplicationInfo();
  },
  methods: {
    getUserInfo() {
      this.$store.dispatch("getUserInfo").then((user) => {
        this.user = user;
      });
    },
    getApplicationInfo() {
      this.$store.dispatch("getApplicationByClientId").then((application) => {
        this.application = application;
      });
    },
    onAllowed() {
      router.push({ name: "processing" });

      this.$store.commit("setAllowedScopes", this.scopes);
      this.$store.dispatch("refreshTokenAndComplete");
    },
    onDeny() {
      router.push({ name: "processing" });
      const payload = {
        error: "Permission denied by User",
      };
      this.postMessage(payload);
    },
    logout() {
      this.$store.dispatch("logout");
    },
    postMessage(payload) {
      const url = localStorage.getItem("redirect_uri");
      window.opener.postMessage(payload, decodeURIComponent(url));
    },
  },
};
</script>

<style scoped lang="scss">
@import "permission";
</style>
