<template>
  <div id="app">
    <common-view></common-view>
  </div>
</template>

<script>
import CommonView from "./components/common-view/common-view";

export default {
  name: "app",
  components: {
    CommonView,
  },
  mounted() {
    this.$store.dispatch("initialize");
  },
};
</script>

<style lang="scss">
@import "style.scss";
@import "./stylesheets/global.scss";
</style>
