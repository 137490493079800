const getRemotePaths = () => {
  const defaults = {
    development: {
      API_PATH: "https://connect-kc.appunite.net",
      COOKIE_DOMAIN: "localhost",
      FIREBASE_API_KEY: "AIzaSyD5af8zf9fmMw9oQI9VbtcqSvjHbHlYQtw",
      FIREBASE_AUTH_DOMAIN: "gistr-app.firebaseapp.com",
      FIREBASE_DATABASE_URL: "https://gistr-app.firebaseio.com",
      FIREBASE_PROJECT_ID: "gistr-app",
      FIREBASE_STORAGE_BUCKET: "gistr-app.appspot.com",
      FIREBASE_MESSAGING_SENDER_ID: "498695447370",
      RECAPTCHA_SITE_KEY: "",
      ENABLE_LOGIN_RECAPTCHA_VERIFICATION: "0", // '1' is true else is false
      ENABLE_SIGNUP_RECAPTCHA_VERIFICATION: "0", // '1' is true else is false
    },

    staging: {
      API_PATH: "https://connect-kc.appunite.net",
      COOKIE_DOMAIN: ".stg.kingschat.online",
      FIREBASE_API_KEY: "AIzaSyD5af8zf9fmMw9oQI9VbtcqSvjHbHlYQtw",
      FIREBASE_AUTH_DOMAIN: "gistr-app.firebaseapp.com",
      FIREBASE_DATABASE_URL: "https://gistr-app.firebaseio.com",
      FIREBASE_PROJECT_ID: "gistr-app",
      FIREBASE_STORAGE_BUCKET: "gistr-app.appspot.com",
      FIREBASE_MESSAGING_SENDER_ID: "498695447370",
      RECAPTCHA_SITE_KEY: "",
      ENABLE_LOGIN_RECAPTCHA_VERIFICATION: "0", // '1' is true else is false
      ENABLE_SIGNUP_RECAPTCHA_VERIFICATION: "0", // '1' is true else is false
    },

    production: {
      API_PATH: "https://connect.kingsch.at",
      COOKIE_DOMAIN: ".kingschat.online",
      FIREBASE_API_KEY: "AIzaSyD5af8zf9fmMw9oQI9VbtcqSvjHbHlYQtw",
      FIREBASE_AUTH_DOMAIN: "gistr-app.firebaseapp.com",
      FIREBASE_DATABASE_URL: "https://gistr-app.firebaseio.com",
      FIREBASE_PROJECT_ID: "gistr-app",
      FIREBASE_STORAGE_BUCKET: "gistr-app.appspot.com",
      FIREBASE_MESSAGING_SENDER_ID: "498695447370",
      RECAPTCHA_SITE_KEY: "",
      ENABLE_LOGIN_RECAPTCHA_VERIFICATION: "0", // '1' is true else is false
      ENABLE_SIGNUP_RECAPTCHA_VERIFICATION: "0", // '1' is true else is false
    },
  };

  return window.ENV !== "$ENV"
    ? JSON.parse(window.ENV)
    : {
        ...defaults[process.env.NODE_ENV],
      };
};

export const REMOTE_PATHS = getRemotePaths();
